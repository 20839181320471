<template>
  <div class="m-form-call-master" :class="formClasses">
    <form class="m-form-call-master__form" @submit.prevent="submitHandler" autocomplete="off">
      <div
        v-if="stateModel === EFormStates.SUCCESS"
        class="m-form-call-master__form-success m-form__success"
      >
        <div class="m-form__status-title">{{ props.successTitle }}</div>

        <div class="m-form__status-subtitle" v-html="props.successSubtitle"></div>

        <div class="m-form__status-button">
          <Button modifier="tertiary" theme="dark" @click="closeForm('success')">Закрыть</Button>
        </div>
      </div>

      <div
        v-else-if="stateModel === EFormStates.ERROR"
        class="m-form-call-master__form-error m-form__error"
      >
        <div class="m-form__status-title">{{ props.errorTitle }}</div>

        <div class="m-form__status-subtitle" v-html="props.errorSubtitle"></div>

        <div class="m-form__status-button">
          <Button modifier="tertiary" theme="dark" @click="closeForm('error')">Вернуться</Button>
        </div>
      </div>

      <div class="m-form-call-master__form-default m-form__default">
        <InputText
          v-model="formData.name"
          label="Имя"
          theme="dark"
          placeholder="Введите имя"
          name="Имя"
        >
        </InputText>

        <InputPhone
          v-model="formData.phone"
          :mask="{
            holdPlaceholder: {
              enable: true,
              placeholder: '+7 (___) ___-__-__',
            },
          }"
          placeholder="+7 (000) 000-00-00"
          label="Телефон*"
          theme="dark"
          :valid="
            !v$.phone.$invalid &&
            !errorsAggregator(v$.phone.$errors, formBackendErrors).filter('phone').length
          "
          :invalid="!!errorsAggregator(v$.phone.$errors, formBackendErrors).filter('phone').length"
          :errors="errorsAggregator(v$.phone.$errors, formBackendErrors).filter('phone')"
          @input="v$.phone.$touch()"
          @change="v$.phone.$touch()"
        >
          <template #errors="{ errors }">
            <span v-for="(error, i) in errors" :key="`error-${i}`">
              {{ error }}
            </span>
          </template>
        </InputPhone>

        <div v-if="getGenericCaptchaState" class="m-form-captcha m-form-captcha--dark">
          <div class="m-form-captcha__header">Введите код с картинки</div>
          <div class="m-form-captcha__box">
            <img
              v-if="captchaImage"
              :src="captchaImage"
              alt="Введите код с картинки"
              title="Введите код с картинки"
              width="120px"
              height="50px"
            />

            <Button
              title="Обновить код капчи"
              aria-label="Обновить код капчи"
              @click="fetchCaptcha"
            >
              <i class="icon-main-reload"></i>
            </Button>
          </div>

          <InputText
            v-model="formData.captcha"
            label="Код*"
            theme="dark"
            placeholder="Введите код"
            name="captcha"
            :invalid="!!errorsAggregator(null, formBackendErrors).filter('captcha').length"
            :errors="errorsAggregator(null, formBackendErrors).filter('captcha')"
          >
            <template #errors="{ errors }">
              <span v-for="(error, i) in errors" :key="`error-${i}`">
                {{ error }}
              </span>
            </template>
          </InputText>
        </div>

        <div class="m-form__form-policy">
          <slot name="policyText">
            Нажимая на кнопку «Отправить», вы соглашаетесь с
            <a href="/docs/privacy_policy.pdf" target="_blank" class="link-dark"
              >политикой обработки персональных данных</a
            >
          </slot>
        </div>

        <Button
          tag="button"
          type="submit"
          css-class="button"
          modifier="primary"
          :loading="stateModel === EFormStates.LOADING || stateModel != EFormStates.DEFAULT"
        >
          <template #loading>
            <Loader></Loader>
          </template>
          <slot name="buttonText">Отправить</slot>
        </Button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
// vue
import { computed, ref } from 'vue';

// Components
import InputText from '@ice-products-ui/vue-library/InputText';
import InputPhone from '@ice-products-ui/vue-library/InputPhone';
import Button from '@ice-products-ui/vue-library/Button';
import Loader from '@ice-products-ui/vue-library/Loader';

// Types
import type { IFormProps, IFormEmits } from '../form.types';
import type { IFormCallMasterModel } from '~/components/M/Forms/MFormCallMaster/MFormCallMaster.model';
import type { IErrorsBackendInterface } from '~/utils/arrays/errorsAggregator';

// Vuelidate
import { useVuelidate } from '@vuelidate/core';
import validations from '../form.validation.messages';

// Models
import { EFormStates, EFormStateMessages } from '~/components/M/Forms/form.types';
import { FormCallMasterModel } from '~/components/M/Forms/MFormCallMaster/MFormCallMaster.model';
import { EFormNames } from '~/composables/molecules/useForms';

// Composables
import useApiBase from '~/composables/api/base/useApiBase';
import useApiCaptcha from '~/composables/api/captcha/useApiCaptcha';
import { useIceGenericRequest } from '#imports';

// Utils
import errorsAggregator from '~/utils/arrays/errorsAggregator';
import QueryBuilder from '~/utils/queries/query-builder';

const emit = defineEmits<IFormEmits<IFormCallMasterModel>>();
const props = withDefaults(defineProps<IFormProps>(), {
  loading: false,
  successTitle: EFormStateMessages.SUCCESS_TITLE_DEFAULT,
  successSubtitle: EFormStateMessages.SUCCESS_SUBTITLE_DEFAULT,
  errorTitle: EFormStateMessages.ERROR_TITLE_DEFAULT,
  errorSubtitle: EFormStateMessages.ERROR_SUBTITLE_DEFAULT,
});

const { baseApiUrl } = useApiBase();
const { getGenericCaptchaState, captchaImage, captchaKey, fetchCaptcha } = await useApiCaptcha(
  EFormNames.FORM_CALLMASTER,
);
const { getGenericContextsData } = useIceGenericRequest();

const stateModel = ref(EFormStates.DEFAULT);
const formData = ref(new FormCallMasterModel({}, getGenericCaptchaState.value));
const formBackendErrors = ref<IErrorsBackendInterface | null>(null);

/**
 * @description Form classes
 */
const formClasses = computed(() => {
  return {
    'm-form--default': stateModel.value === EFormStates.DEFAULT,
    'm-form--loading': stateModel.value === EFormStates.LOADING,
    'm-form--success': stateModel.value === EFormStates.SUCCESS,
    'm-form--error': stateModel.value === EFormStates.ERROR,
  };
});

/**
 * @description Validation rules
 * @returns {Object}
 */
const rules = computed(() => {
  return {
    phone: {
      required: validations.required(),
      minLength: validations.phoneMin(18),
      maxLength: validations.phoneMax(18),
    },
  };
});

const v$ = useVuelidate(rules, formData);

/**
 * @description Form submit handler
 */
const submitHandler = async () => {
  v$.value.$touch();

  if (v$.value.$invalid) return;

  stateModel.value = EFormStates.LOADING;

  if (getGenericCaptchaState.value) {
    formData.value.captcha_key = captchaKey.value;
  }

  const queryStr = new QueryBuilder({
    contexts: getGenericContextsData.value,
  })
    .clearQuery()
    .buildQueryString();

  await $fetch(`forms/call_master?${queryStr}`, {
    baseURL: baseApiUrl,
    method: 'POST',
    body: formData.value,
    onResponseError: (context) => {
      if (context.response.status === 422) {
        formBackendErrors.value = context.response._data;
        stateModel.value = EFormStates.DEFAULT;
        fetchCaptcha();
        return;
      }

      stateModel.value = EFormStates.ERROR;
      emit('update:state', EFormStates.ERROR);
    },
  });

  stateModel.value = EFormStates.SUCCESS;
  emit('update:state', EFormStates.SUCCESS);

  await fetchCaptcha();
};

/**
 * @description Close form handler
 * @param {string} status
 */
const closeForm = (status: string) => {
  stateModel.value = EFormStates.DEFAULT;
  emit('update:state', EFormStates.DEFAULT);

  if (status === EFormStates.SUCCESS) {
    formData.value.reset();
    v$.value.$reset();
  }
};
</script>
